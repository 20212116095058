import { Box, Button, Container, Typography } from '@mui/material';
import { TableRegistered } from './TableRegistered';
import { Motion } from '../../Motion';
import axios from '../../../helper/axios';
import { useEffect, useState } from 'react';
import { DownloadRegis } from './Download';


export const Registered = () => {
    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);
    const [rows, setRows] = useState([])
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value, 10);
        setPage(0);
    };

    const getData = async () => {
        try {
            const { data } = await axios.get(`/portal/siswa?page=${page + 1}&limit=10`)
            setRows(data?.rows)
            setCount(data?.count)
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        getData()
    }, [page])

    return (
        <Motion>
            <Container>
                <Box sx={{
                    margin: '20px 0 100px',
                    border: '1px solid #e6e7e9'
                }}>
                    <Box sx={{
                        display: 'flex',
                        padding: '10px 15px',
                        bgcolor: '#fff',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                        <Typography>Data Pendaftar</Typography>
                        <DownloadRegis />
                    </Box>
                    <TableRegistered
                        rows={rows}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        page={page}
                        count={count}
                        rowsPerPage={rowsPerPage}
                    />
                </Box>
            </Container>
        </Motion>
    );
}