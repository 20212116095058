import { Button, CircularProgress } from "@mui/material"
import axios from "../../../helper/axios";
import { useState } from "react";
import { toast } from "react-toastify";

export const DownloadRegis = () => {
    const [loading, setLoading] = useState(false)
    const onDownload = async () => {
        try {
            setLoading(true);
            const { data } = await axios.get(
                `/portal/siswa/excell`,
                {
                    responseType: 'blob',
                },
            );
            const url = window.URL.createObjectURL(new Blob([data]));
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = `Data PSB.xlsx`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            setLoading(false);
            toast.success("Berhasil");
        } catch (err) {
            console.log(err);
            toast.error("Gagal Download")
            setLoading(false);
        }
    };
    return (
        <Button variant='contained' onClick={onDownload} sx={{
            type: "submit",
            color: 'primary',
            fontSize: '10px'
        }}>
            {loading ? <CircularProgress size={24} color="inherit" /> : 'Download'}
        </Button>
    )
}